import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
  name: "toast",
  initialState: {
    isVisible: false,
    message: null,
    type: "info",
  },
  reducers: {
    showToast(state, action) {
      (state.isVisible = true),
        (state.type = "info"),
        (state.message = action.payload?.message);
    },
    showSuccessToast(state, action) {
      (state.isVisible = true),
        (state.type = "success"),
        (state.message = action.payload?.message);
    },
    showErrorToast(state, action) {
      (state.isVisible = true),
        (state.type = "error"),
        (state.message = action.payload?.message);
    },
    hideToast(state) {
      state.isVisible = false;
      (state.message = null), (state.type = "info");
    },
  },
});

export const { showToast, showSuccessToast, showErrorToast, hideToast } =
  toastSlice.actions;

export default toastSlice;
