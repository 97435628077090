import { createSlice } from "@reduxjs/toolkit";

const offcanvasSlice = createSlice({
  name: "offcanvas",
  initialState: {
    isVisible: false,
    web: {
      url: "",
      title: "",
    },
  },
  reducers: {
    showWebOffcanvas(state, action) {
      (state.isVisible = true),
        (state.web.url = action.payload.url),
        (state.web.title = action.payload.title);
    },
    hideOffcanvas(state) {
      state.isVisible = false;
    },
  },
});

export const { showWebOffcanvas, hideOffcanvas } = offcanvasSlice.actions;

export default offcanvasSlice;
