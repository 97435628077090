import { createSlice } from "@reduxjs/toolkit";

const notifSlice = createSlice({
  name: "notif",
  initialState: {
    isVisible: false,
    title: "",
    timeString: "",
    image: "",
    link: "",
  },
  reducers: {
    showNotif(state, action) {
      (state.isVisible = true),
        (state.title = action.payload?.title),
        (state.timeString = action.payload?.timeString),
        (state.image = action.payload?.image),
        (state.link = action.payload?.link);
    },

    hideNotif(state) {
      state.isVisible = false;
    },
  },
});

export const { showNotif, hideNotif } = notifSlice.actions;

export default notifSlice;
