import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "reduxstore/slices/alert";
import Icon from "./Icon";

const Alert = () => {
  const dispatch = useDispatch();
  const data = useSelector(
    (state: {
      alert: {
        isVisible: boolean;
        title: string;
        type: string;
        description: string;
        primaryButtonText: string;
        secondaryButtonText: string;
        onCancel: () => {};
        onConfirm: () => {};
      };
    }) => state.alert
  );

  const {
    isVisible,
    title,
    type,
    description,
    primaryButtonText,
    secondaryButtonText,
    onCancel,
    onConfirm,
  } = data;

  if (!isVisible) {
    return <></>;
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(hideAlert());
  };
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    dispatch(hideAlert());
  };

  return (
    <>
      <div
        className="relative z-[100]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <Icon type={type} />
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {title && title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {description && description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {primaryButtonText && (
                  <button
                    type="button"
                    onClick={handleConfirm}
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  >
                    {primaryButtonText}
                  </button>
                )}

                {secondaryButtonText && (
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    {secondaryButtonText}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Alert;
