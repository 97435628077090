"use client";
import { Provider } from "react-redux";
import Alert from "./modal/Alert";
import store from "reduxstore";
import {
  showErrorAlert as showErrorAlertAction,
  showSuccessAlert as showSuccessAlertAction,
  hideAlert as hideAlertAction,
  showConfirmAlert as showConfirmAlertAction,
} from "reduxstore/slices/alert";
import AlertWithDescription from "./oncanvas/AlertWithDescription";

/* export alert modal */
export const AlertComponent = () => {
  return (
    <Provider store={store}>
      <Alert />
    </Provider>
  );
};

/**
{
  title: "",
  description: "",
  secondaryButtonText: "",
  primaryButtonText: "",
  onCancel: func,
  onConfirm: func
}
 */

/** export redux actions */
export const showErrorAlert = (payload: Object) => {
  store.dispatch(showErrorAlertAction(payload));
};

export const showSuccessAlert = (payload: Object) => {
  store.dispatch(showSuccessAlertAction(payload));
};

export const showConfirmAlert = (payload: Object) => {
  store.dispatch(showConfirmAlertAction(payload));
};

export const hideAlert = () => {
  store.dispatch(hideAlertAction());
};

/** export other on-canvas alerts */
export { AlertWithDescription };
