import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    isVisible: false,
    type: "error",
    title: "",
    description: "",
    primaryButtonText: "",
    secondaryButtonText: "",
    onCancel: null,
    onConfirm: null,
  },
  reducers: {
    showErrorAlert(state, action) {
      (state.isVisible = true),
        (state.type = "error"),
        (state.title = action.payload?.title),
        (state.description = action.payload?.description),
        (state.primaryButtonText = action.payload?.primaryButtonText),
        (state.secondaryButtonText = action.payload?.secondaryButtonText),
        (state.onCancel = action.payload?.onCancel),
        (state.onConfirm = action.payload?.onConfirm);
    },
    showSuccessAlert(state, action) {
      (state.isVisible = true),
        (state.type = "success"),
        (state.title = action.payload?.title),
        (state.description = action.payload?.description),
        (state.primaryButtonText = action.payload?.primaryButtonText),
        (state.secondaryButtonText = action.payload?.secondaryButtonText),
        (state.onCancel = action.payload?.onCancel),
        (state.onConfirm = action.payload?.onConfirm);
    },
    showConfirmAlert(state, action) {
      (state.isVisible = true),
        (state.title = action.payload?.title),
        (state.description = action.payload?.description),
        (state.primaryButtonText = action.payload?.primaryButtonText),
        (state.secondaryButtonText = action.payload?.secondaryButtonText),
        (state.onCancel = action.payload?.onCancel),
        (state.onConfirm = action.payload?.onConfirm);
    },
    hideAlert(state) {
      state.isVisible = false;
    },
  },
});

export const { showErrorAlert, showSuccessAlert, showConfirmAlert, hideAlert } =
  alertSlice.actions;

export default alertSlice;
