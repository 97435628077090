import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alert";
import notifSlice from "./slices/notification";
import offcanvasSlice from "./slices/offcanvas";
import searchSlice from "./slices/search";
import toastSlice from "./slices/toast";

export default configureStore({
  reducer: {
    alert: alertSlice.reducer,
    offcanvas: offcanvasSlice.reducer,
    search: searchSlice.reducer,
    notif: notifSlice.reducer,
    toast: toastSlice.reducer,
  },
});
